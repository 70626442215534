/* NotFound.css */

.not-found-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: radial-gradient(circle, #ece9e6, #ffffff);
    color: #333;
    text-align: center;
    font-family: 'Roboto', sans-serif;
  }
  
  .not-found-content {
    max-width: 500px;
    padding: 30px;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
  }
  
  .not-found-illustration {
    margin-bottom: 20px;
  }
  
  .not-found-svg {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    animation: rotate 4s linear infinite;
  }
  
  @keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .not-found-title {
    font-size: 100px;
    font-weight: bold;
    color: #e74c3c;
    margin: 0;
    position: relative;
  }
  
  .not-found-title::after {
    content: '';
    display: block;
    width: 50px;
    height: 5px;
    background: #e74c3c;
    margin: 10px auto;
    border-radius: 5px;
    opacity: 0.5;
  }
  
  .not-found-subtitle {
    font-size: 24px;
    color: #7f8c8d;
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .not-found-text {
    font-size: 18px;
    color: #34495e;
    margin-bottom: 20px;
  }
  
  .not-found-link {
    display: inline-block;
    padding: 12px 25px;
    font-size: 18px;
    color: #ffffff;
    background: #3498db;
    text-decoration: none;
    border-radius: 30px;
    transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .not-found-link:hover {
    background: #2980b9;
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  