.text-banner {
    transition: 0.3s;
    background-color: hwb(0 4% 95% / 0.4);
    border-radius: 10px;
    margin-top: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    border: 2px solid black;
    max-width: 90%;
}

@media screen and (min-width: 1000px) {
    .text-banner {
        max-width: 55%;
    }
    
}

.text-banner:hover {
    background-color: hwb(60 4% 95% / 0.6);
    transition: 0.3s;
    border-radius: 0px;

}

@font-face {
    font-family: 'Play-Bold';
    src: url('../Font/Play-Bold.ttf') format('truetype');
  }
  
  .font-play-bold {
    font-family: 'Play-Bold', sans-serif !important;
  }

  .red {
    background-color: red;
  }

  .blue {
    background-color: blue;
  }

  .main-container {
    max-width: 97%;
  }

  .myFace {
    transition: 0.5s;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    border: 2px solid white;
  }

  .myFace:hover {
    transition: 0.5s;
    border-radius: 3px;
    width: 200px;
    height: 200px;
    border: 2px solid white;
  }

  .presentation-txt {
    text-align: justify;
    text-justify: inter-word;
  }

  .skills-img {
    transition: 0.5s;
    transform: scale(0.8);
    width: 70%;
    padding: 10px;
  }

  .skills-img:hover {
    transition: 0.5s;
    transform: scale(1);
  }

  .youtube_video {
    transition: 0.4s;
    border-radius: 10px;
    border: 1px solid white;
    transform : scale(0.94);
  }

  .youtube_video:hover {
    transition: 0.4s;
    border-radius: 3px;
    transform : scale(1);
  }

  .usefull-box {
    width: 50%;
    color: white;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .underline {
    text-decoration: underline;
  }