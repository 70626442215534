/* Footer.css */

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: 0.3s;
    background-color: hwb(0 4% 95% / 0.4);
    padding-top: 10px;
    padding-bottom: 20px;
    border-top: 2px solid black;
    text-align: center;
}
