body {
  min-height: 100vh;
  background-image: url('Home.jpg');
  background-size: cover;
  background-attachment: fixed; /* Fixer l'image pour éviter les variations */
  background-position: center;  /* Centrer l'image pour éviter les variations dues au contenu */
  background-repeat: no-repeat;
}

.navbar {
  border-bottom: 1px solid #444;
}

.navbar-brand img {
  width: 80px;
  transition: width 0.3s ease;
}

.navbar-nav {
  align-items: center;
}

.navbar .navbar-nav .nav-link {
  color: #fff;
  font-size: 1.1em;
  padding: 0.5em 1em;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.navbar-background {
  background-color: hsl(100, 49%, 52%, 0.06);
  backdrop-filter: blur(10px);
}

@media screen and (min-width: 768px) {
  .navbar-brand img {
    width: 100px;
  }

  .navbar-brand {
    margin-right: 0;
    padding: 0 1em;
  }
}

.custom-toggler {
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.custom-toggler-icon {
  width: 24px;
  height: 24px;
  position: relative;
  transition: opacity 0.3s ease;
}

.custom-toggler-icon rect {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Animation for the SVG lines */
.custom-toggler:not(.collapsed) .custom-toggler-icon rect:nth-child(1) {
  transform: rotate(45deg);
  transform-origin: 50% 50%;
  opacity: 0;
}

.custom-toggler:not(.collapsed) .custom-toggler-icon rect:nth-child(2) {
  transform: scale(0);
}

.custom-toggler:not(.collapsed) .custom-toggler-icon rect:nth-child(3) {
  transform: rotate(-45deg);
  transform-origin: 50% 50%;
  opacity: 0;
}

.custom-toggler:hover .custom-toggler-icon rect {
  fill: #3498db;
}

@media screen and (max-width: 767px) {
  .custom-toggler {
    width: 50px;
    height: 50px;
  }

  .custom-toggler-icon {
    width: 30px;
    height: 30px;
  }
}
